<template>
    <div>
      <el-row>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="优惠券类型">
            <el-select v-model="orderType" placeholder="请选择" clearable>
              <el-option
                v-for="item in orders"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="使用情况">
            <el-select v-model="status" placeholder="请选择" clearable>
              <el-option
                v-for="item in useStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户电话">
            <el-select
              v-model="selected_user"
              placeholder="请选择"
              value-key="user_id"
              filterable
              remote
              :remote-method="getUser"
              clearable
            >
              <el-option
                v-for="item in userList"
                :key="item.user_id"
                :label="item.nick_name"
                :value="item.user_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
            @click="search"
            style="background-color: #ff7e00; color: #ffffff"
            >搜索</el-button
          >
          </el-form-item>
  
        
        </el-form>
        <el-table
          :data="payList"
          :fit="true"
          :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }"
          v-loading="is_loading"
        >
          <el-table-column label="id" prop="id"></el-table-column>
          <el-table-column
            label="消费券名称"
            prop="map.commodityCouponEntity.name"
          ></el-table-column>
          <el-table-column label="类型" prop="type"></el-table-column>
          <el-table-column label="发放时间" prop="create_time"></el-table-column>
          <el-table-column label="用户电话" prop="map.userInfo.mobile"></el-table-column>
          <el-table-column label="使用状态">
            <template slot-scope="{ row }">
              <div v-if="row.status == 1" style="color: green">未使用</div>
              <div v-if="row.status == 2">已失效</div>
              <div v-if="row.status == 3">已使用</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200px">
            <template slot-scope="{ row }">
              <el-button type="primary" @click="getUserInfo(row)" size="small"
                >查看用户</el-button
              >
              <!-- <el-button v-if="row.status==3" type="success" @click="updateStatus(row,1)" size="small"
                >还原</el-button
              > -->
              <!-- <el-button v-if="row.status==1" type="success" @click="updateStatus(row,3)" size="small"
                >使用</el-button
              > -->
              <el-popconfirm title="确定删除这张优惠券吗？" @confirm="updateStatus(row,10)">
              <el-button type="danger" slot="reference" size="small">删除</el-button >
            </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="changeCurrentPage"
          :currentPage="currentPage"
          :total="payTotal"
        ></paging-fy>
      </el-row>
  
      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
      >
        <el-descriptions title="用户信息" :column="2">
          <el-descriptions-item label="用户名">{{
            user.nick_name
          }}</el-descriptions-item>
          <el-descriptions-item label="手机号">{{
            user.mobile
          }}</el-descriptions-item>
          <el-descriptions-item label="id">{{
            user.user_id
          }}</el-descriptions-item>
          <el-descriptions-item label="性别">{{
            sex(user.sex)
          }}</el-descriptions-item>
        </el-descriptions>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </template>
    
    <script>
  export default {
    data() {
      return {
        payList: [],
        ks: "",
        is_loading: true,
        dialogVisible: false,
        is_loading: false,
        currentPage: 1,
        payTotal: 0,
        temp: {},
        payList: [],
        options: [
          {
            value: "true",
            label: "已支付",
          },
          {
            value: "false",
            label: "未支付",
          },
        ],
        phone: "",
        mechanism_name_list: [],
        amount: "",
        nickname: "",
        orderType: "",
        orders: [
          {
            value: "消费券",
            label: "消费券",
          },
            {
            value: "会员卡",
            label: "会员卡",
          },
            {
            value: "优学卡",
            label: "优学卡",
          }
        ],
        status: "1",
        get_type:'',
        useStatus: [
          {
            value: "1",
            label: "未使用",
          },
          {
            value: "2",
            label: "已过期",
          },
          {
            value: "3",
            label: "已使用",
          },
        ],
        user: {},
        selected_user: "",
        userList: [],
        selected_coup: "",
        coupList: [],
      };
    },
    created(){
      // this.GetTypeList()
    },
  
    mounted() {
      this.getRefoundList();
    },
    methods: {
      //修改券状态
      updateStatus(row,status){
        this.$axios({
          url:'/user/userCoupon/update',
          method:'post',
          data:{
            id:row.id,
            status:status
          }
        }).then(res=>{
          this.$message({message:res.data.message})
          this.getRefoundList();
        })
      },
      GetTypeList() {
          let url = "/user/commoditycouponType/queryManagerListPage";
          this.$axios
            .get(url, {
              params: {
                status: 2,
              },
            })
            .then((res) => {
              this.orders = res.data.data.rows;
            });
        },
      coupchange(v) {
        this.orderType = v.type;
        console.log(v);
      },
      getUser(query) {
        this.user_list = [];
        if (query && typeof query == "string") {
          let url = "/user/user/queryByMessage";
          this.$axios
            .get(url, {
              params: {
                login_name: query,
              },
            })
            .then((res) => {
              this.userList = res.data.data.rows;
            });
        }
      },
      getCoupByName(query) {
        this.user_list = [];
        if (query && typeof query == "string") {
          let url = "/user/commoditycoupon/queryManagerListPage";
          this.$axios
            .get(url, {
              params: {
                name: query,
              },
            })
            .then((res) => {
              this.coupList = res.data.data.rows;
            });
        }
      },
      sex(v) {
        if (v == 0) {
          return "未知";
        }
        if (v == 1) {
          return "男";
        }
        if (v == 2) {
          return "女";
        }
      },
      //id查用户信息
      getUserInfo(row) {
        this.user = row.map.userInfo
        this.dialogVisible = true
      },
      touser(row) {
        this.$router.push("/main/userDetails/" + row.user_id);
      },
      //券列表
      getRefoundList() {
        this.is_loading = true;
        let url = "/user/userCoupon/queryManagerListPage";
        let params = {
          pageSize: 10,
          currentPage: this.currentPage,
          get_type:'兑换码'
        };
        if (this.orderType) {
          params.type = this.orderType;
        }
        if (this.status) {
          params.status = this.status;
        }
        if (this.selected_user) {
          params.user_id = this.selected_user;
        }
        this.$axios
          .get(url, {
            params,
          })
          .then((res) => {
            this.payList = res.data.data.rows;
            this.payTotal = res.data.data.total;
            this.is_loading = false;
          });
      },
      search() {
        this.currentPage = 1;
        this.getRefoundList();
      },
      changeCurrentPage(v) {
        console.log(v);
        this.currentPage = v;
        this.getRefoundList();
      },
      refuse(row) {
        this.temp = row;
        this.refuseReason = true;
      },
      showRefuse(row) {
        console.log(row);
        this.temp = row;
        this.showReason = true;
      },
    },
  };
  </script>
    
    <style lang="less">
  .expand-form {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 20px;
    line-height: 1.5;
    .el-form-item {
      width: 40%;
    }
  }
  </style>
    