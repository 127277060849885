<template>
  <div class="container">
    <div class="search" style="display: flex; flex-wrap: wrap">
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="券类型">
          <el-select v-model="search_type" placeholder="请选择券" clearable>
            <el-option
              label="海马会员卡"
              value="海马会员卡"
            >
            </el-option>
            <el-option
              v-for="(item, index) in coupon_list"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="coup_status" placeholder="请选择状态">
            <el-option label="启用中" value="2"> </el-option>
            <el-option label="未启用" value="1"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit()">查询</el-button>
        </el-form-item>
        <el-form-item v-permission="'createCouponCode'">
          <el-button type="warning"  @click="isFlag">生成兑换码</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 批量领取 -->

    <!-- 列表 -->
    <div>
      <textarea id="input" style="position: fixed; left: -100%"></textarea>

      <div class="contentList">
        <div class="paging">
          <el-table
            ref="multipleTable"
            :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column
              prop="id"
              align="center"
              label="id"
            ></el-table-column>
            <el-table-column
              prop="coupon_type"
              align="center"
              label="券类型"
            ></el-table-column>
            <el-table-column
              prop="create_count"
              align="center"
              label="张数"
            ></el-table-column>
            <!-- <el-table-column prop="send_id" align="center" width="200" label=""></el-table-column> -->
            <el-table-column prop="channels" align="center" label="发放来源">
            </el-table-column>
            <el-table-column prop="start_num" align="center" label="号段">
            </el-table-column>
            <el-table-column prop="status" align="center" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1">未启用</span>
                <span v-if="scope.row.status == 2">已启用</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="create_time"
              align="center"
              label="生成时间"
              width="200px"
            ></el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <div class="flex flex-wrap">
                  <el-button
                    @click="toDetails(scope.row.id)"
                    type="primary"
                    size="small"
                    >查看详情</el-button
                  >
                  <el-button
                    @click="open(scope.row)"
                    type="success"
                    size="small"
                    v-if="scope.row.status == 1"
                    >启用</el-button
                  >
                  <el-button
                    @click="close(scope.row)"
                    type="danger"
                    size="small"
                    v-if="scope.row.status == 2"
                    >关闭</el-button
                  >
                  <el-button
                    @click="importExcel(scope.row)"
                    type="warning"
                    size="small"
                    >导出</el-button
                  >
                  <!-- <el-button
                    @click="Allopen(scope.row)"
                    type="text"
                    size="small"
                    >全部启用</el-button
                  > -->
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页-->

          <paging-fy
            @currentPageChange="handleCurrentChange"
            :currentPage="currentPage"
            :total="total"
          ></paging-fy>
        </div>
      </div>
      <input
        id="copy_content"
        type="text"
        value=""
        style="position: absolute; top: 0; left: 0; opacity: 0; z-index: -10"
      />
    </div>
    <el-dialog :visible="groupListShow" width="90%" :before-close="handleClose">
      <el-table :data="coupon_list_" size="small" @selection-change="selChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          :formatter="fromTitle"
          prop="map.getUserInfo.nick_name"
          align="center"
          width="200"
          label="发放用户"
        ></el-table-column>
        <el-table-column
          :formatter="fromTitle"
          prop="map.getUserInfo.mobile"
          align="center"
          width="300"
          label="手机号"
        >
        </el-table-column>
        <el-table-column
          prop="start_num"
          align="center"
          width="200"
          label="卡号"
        >
        </el-table-column>
        <el-table-column
          prop="coupon_code"
          align="center"
          width="200"
          label="兑换码"
        ></el-table-column>
        <el-table-column prop="status" align="center" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 5">未启用</span>
            <span v-if="scope.row.status == 2">已启用</span>
            <span v-if="scope.row.status == 1">已领取</span>
            <span v-if="scope.row.status == 4">已过期</span>
            <span v-if="scope.row.status == 6">失效</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          align="center"
          width="300"
          label="发放时间"
        ></el-table-column>
        <el-table-column prop="" align="center" width="300" label="操作">
          <template slot-scope="scope">
            <el-button
              size="small"
              v-if="scope.row.status == 5"
              @click="openit(scope.row, { status: 2 })"
              >启用</el-button
            >
            <el-button
              size="small"
              v-if="scope.row.status == 2"
              @click="openit(scope.row, { status: 5 })"
              >关闭</el-button
            >
            <el-button
              size="small"
              v-if="scope.row.status == 5 || scope.row.status == 2"
              type="danger"
              @click="openit(scope.row, 6)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 生成兑换码 -->
    <el-dialog
      title="生成兑换码码包"
      :visible.sync="flag"
      :width="ks ? '90%' : '30%'"
      :before-close="handleClose"
    >
      <div class="login">
        <div class="popupcontents">
          <el-form ref="form" label-width="100px" style="padding-right: 40px">
            <el-form-item label="卡券类型" required>
              <el-radio v-model="send_type" label="优惠券">优惠券</el-radio>
              <el-radio v-model="send_type" label="会员卡">会员卡</el-radio>
            </el-form-item>
            <el-form-item label="发放卡券" required>
              <el-select
                v-if="send_type == '优惠券'"
                v-model="coupon"
                placeholder="请选择券"
                remote
                @focus="getCoupon"
                @change="getCouponType"
                value-key="id"
              >
                <el-option
                  v-for="(item, index) in coupon_list"
                  :key="index"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-select
                v-else
                v-model="coupon"
                placeholder="请选择会员卡"
                remote
                @focus="getMember"
                @change="getCouponType"
                value-key="id"
              >
                <el-option
                  v-for="(item, index) in member_list"
                  :key="index"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="生成数量" required>
              <el-input
                v-model="grantNum"
                type="number"
                placeholder="请输入码生成的数量"
              ></el-input>
              <span style="position: absolute; right: 80px">条</span>
            </el-form-item>
            <el-form-item label="有效期" required>
              <el-input
                v-model="overdue"
                type="number"
                placeholder="请输入兑换码的有效期"
              ></el-input>
              <span style="position: absolute; right: 80px">天</span>
            </el-form-item>
            <el-form-item label="发卡方">
              <el-select v-model="channels" placeholder="请选择发卡方">
                <el-option label="平台" value="平台"> </el-option>
                <el-option label="机构" value="机构"> </el-option>
                <el-option label="合作商" value="合作商"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="社区机构" v-if="channels == '机构'" required>
              <mechanism-select :mecid.sync="mechanism_id"></mechanism-select>
            </el-form-item>
            <el-form-item label="合作商" v-if="channels == '合作商'" required>
              <cooperatorSelect :coopid.sync="coop_id" @change="coopChange"></cooperatorSelect>
            </el-form-item>
            <el-form-item label="社区机构"  v-if="channels == '合作商'" required>
              <mechanism-select :mecid.sync="community_id" ref="community" :condition="{cooperator_id:coop_id}" ></mechanism-select>
            </el-form-item>
            <el-form-item label="起始号段">
              <el-input
                v-model="start_num"
                placeholder="请输入卡券的起始号段"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button style="margin-left: 30px" @click="toclose"
                >取消</el-button
              >
              <el-button
                style="margin-left: 110px"
                type="primary"
                @click="generateCoupon"
                >确认</el-button
              >
            </el-form-item>
            <div class="btns"></div>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <!-- 导出 -->
    <el-dialog title="导出兑换码" :visible.sync="eflag" width="30%">
      <div class="export-box">
        <div class="popupcontents">
          <el-form ref="form" label-width="100px" style="padding-right: 40px">
            <el-form-item label="导出数量">
              <el-input
                v-model="import_num"
                type="number"
                placeholder="请输要导出的数量"
              ></el-input>
              <span style="position: absolute; right: 80px">条</span>
            </el-form-item>
            <el-form-item>
              <el-button style="margin-left: 30px" @click="eflag = false"
                >取消</el-button
              >
              <el-button
                style="margin-left: 30px"
                type="primary"
                @click="exportCodeAll('part')"
                >部分导出</el-button
              >
              <el-button
                style="margin-left: 30px"
                type="primary"
                @click="exportCodeAll('all')"
                >全部导出</el-button
              >
            </el-form-item>
            <div class="btns"></div>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import XLSX from "xlsx";
import { Loading } from "element-ui";
import MechanismSelect from "../../../components/select/mechanismSelect.vue";
import cooperatorSelect from "../../../components/select/cooperatorSelect.vue";

export default {
  name: "LiveStreaming",
  components: { MechanismSelect, cooperatorSelect },
  data() {
    return {
      loading: true,
      ss: 5,
      nav: {
        firstNav: "优惠券管理",
        secondNav: "生成兑换码",
      },
      send_type: "优惠券",
      eflag: false,
      tableData: [], //总数据
      flag: false,
      coupon_length: null,
      show: false,
      codeShow: false,
      code: "",
      id: [],
      groupListShow: false,
      user_id: "",
      total: 0,
      temp_id: null,
      channels: null,
      start_num: null,
      commodity_type: null,
      name: "",
      phone: "",
      coupon_list: [],
      coupon_id: null,
      coupon: "",
      is_pay: null,
      couponCode: "",
      grantNum: "",
      overdue: "", //添加条数
      sels: [], //选中数据组

      form: {
        user: "",
        date1: "",
      },
      coupon_list_: [],
      member_list: [],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      dorw: false,
      ks: "",
      coup_status: "",
      search_type: "",
      import_num: "",
      imoprt_coup: {},
      real_num: 0,
      excelName: "兑换码.xlsx",
      mechanism_id: "",
      coop_id:'',
community_id:''
    };
  },

  mounted() {
    this.getCoupon();
    this.GetList();
  },
  methods: {
    coopChange(){
        this.$refs.community.searchOrgName()
    },
    //导出数据
    exportCodeAll(type) {
      let n = 0;
      //计算请求次数，每次上限返回100条
      if (type == "part") {
        if (!this.import_num) {
          this.$message({ message: "请输入要导出的条数" });
          return;
        }
        this.real_num =
          this.import_num >= this.imoprt_coup.create_count
            ? parseInt(this.imoprt_coup.create_count)
            : parseInt(this.import_num);
        n = Math.ceil(this.real_num / 100);
      } else {
        n = Math.ceil(this.imoprt_coup.create_count / 100);
      }

      let promiseList = [];
      for (let i = 1; i <= n; i++) {
        let size = 0;
        if (this.real_num < 100) {
          size = this.real_num;
        } else {
          size = 100;
        }
        this.real_num -= size;
        promiseList.push(this.getCodeList(size, i));
      }
      let loadingInstance = Loading.service({ text: "正在导出..." });
      //所有请求完成后生成表格
      Promise.all(promiseList)
        .then((val) => {
          this.ganareExcel(val);
          this.eflag = false;
          this.$nextTick(() => {
            loadingInstance.close();
          });
        })
        .catch(() => {
          this.$message({ message: "导出失败" });
          this.$nextTick(() => {
            loadingInstance.close();
          });
        });
    },
    //获取兑换码数据
    getCodeList(size, page) {
      let id = this.imoprt_coup.id;
      let url = "/user/couponList/queryByMessage";
      return new Promise((resolve, reject) => {
        this.$axios
          .get(url, {
            params: {
              send_id: id,
              currentPage: page,
              pageSize: size,
            },
          })
          .then((res) => {
            resolve(res.data.data.rows);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    //生成表格
    ganareExcel(data) {
      // 需要导出的数据
      let exportArr = [];
      data.forEach((arr) => {
        exportArr = [...exportArr, ...arr];
      });

      const filterVal = [
        "start_num",
        "coupon_code",
        "coupon_type",
        "create_time",
        "overdue_time",
      ];
      exportArr = exportArr.map((item) => filterVal.map((v) => item[v]));
      console.log(exportArr);
      // 自定义下载的header，注意是数组中的数组哦
      const Header = [["编号", "兑换码", "卡券类型", "生成时间", "过期时间"]];

      // 将JS数据数组转换为工作表。
      const headerWs = XLSX.utils.aoa_to_sheet(Header);
      const ws = XLSX.utils.sheet_add_json(headerWs, exportArr, {
        skipHeader: true,
        origin: "A2",
      });
      //设置表格的宽度
      ws["!cols"] = [
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
        {
          wpx: 150,
        },
        {
          wpx: 150,
        },
      ];
      /* 新建空的工作表 */
      const wb = XLSX.utils.book_new();

      // 可以自定义下载之后的sheetname
      XLSX.utils.book_append_sheet(wb, ws, "sheetName");

      /* 生成xlsx文件 */
      XLSX.writeFile(wb, this.excelName);
    },
    //
    importExcel(row) {
      this.eflag = true;
      this.imoprt_coup = row;
      this.excelName = `${row.coupon_type}兑换码-${
        row.start_time.split(" ")[0]
      }.xlsx`;
    },
    selChange(ser) {
      console.log(ser);
    },
    selectiondrawCode(selection) {
      //  console.log(selection,"当列数据111")
      this.sels = selection;
    },

    renew() {
      this.codeShow = false;
    },
    toDetails(id) {
      this.temp_id = id;
      let url = "/user/couponList/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            send_id: id,
          },
        })
        .then((res) => {
          this.coupon_list_ = res.data.data.rows;
          this.coupon_list_total = res.data.data.total;
          this.groupListShow = true;
        });
    },
    getCouponType(v) {
      this.coupon = v;
      this.coupon_id = v.id;
      this.is_pay = null;
    },
    openit(row, option) {
      let url = "/user/couponList/update";
      this.$axios
        .post(url, {
          id: row.id,
          ...option,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "成功!",
            });
            this.GetList();
            this.toDetails(this.temp_id);
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },
    open(row) {
      let url = "/user/couponListSend/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: 2,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "修改成功!",
              type: "success",
            });
            this.GetList();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((res) => {});
    },
    close(row) {
      let url = "/user/couponListSend/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: 1,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "修改成功!",
              type: "success",
            });
            this.GetList();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((res) => {});
    },
    isFlag() {
      this.flag = true;
      //  console.log("弹框",this.flag)
    },
    filterChange(filterObj) {
      //  console.log(filterObj, "当列数据");
    },
    handleCurrentChange(val) {
      //  console.log(`当前页: `, val);
      this.currentPage = val;
      this.GetList();
    },
    copyCode(row) {
      //  console.log(row);
      this.id = [];
      this.code = row.coupon_code;
      this.id.push(row.id);
      this.user_id = row.user_id;
      this.copy();
    },
    // 复制
    copy() {
      let text = this.code;
      this.sels.forEach((e) => {
        text + e.coupon_code;
      });
      var input = document.getElementById("input");
      input.value = text; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行浏览器复制命令
      this.$message(
        {
          message: "复制成功",
          type: "success",
        },
        2000
      );
    },
    getCoupon() {
      let url = "/user/commoditycoupon/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            currentPage: 1,
            pageSize: 20,
            categories: "体育运动",
            type: "消费券",
          },
        })
        .then((res) => {
          this.coupon_list = res.data.data.rows;
        });
    },
    getMember() {
      let url = "/user/member/card/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 1,
            currentPage: 1,
            pageSize: 20,
            type: 1,
          },
        })
        .then((res) => {
          this.member_list = res.data.data.rows;
        });
    },
    fromTitle(row, column, cellValue) {
      if (cellValue === "" || cellValue == null) {
        return "暂无";
      } else {
        return cellValue;
      }
    },
    // 关闭弹框
    toclose() {
      this.flag = false;
      this.codeShow = false;
      this.show = false;
    },
    GetList() {
      let url = "/user/couponListSend/queryByMessage";
      let data = {
        pageSize: 10,
        currentPage: this.currentPage,
        status: this.coup_status,
      };
      if (this.search_type != "") {
        data.coupon_type = this.search_type;
      }
      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
        });
    },
    // 添加优惠券
    generateCoupon() {
      if (this.grantNum == "" && this.overdue == "") {
        this.$message(
          {
            message: "请填写数量和有效期",
            type: "error",
          },
          2000
        );
      } else {
        let data = {
          number: parseInt(this.grantNum),
          id: this.coupon_id,
          coupon_length: 8,
          user_id: JSON.parse(localStorage.getItem("managerInfo")).user_id,
          duration: this.overdue * 60 * 24,
          start_num: this.start_num,
          channels: this.channels,
          type: this.send_type,
          mechanism_id:this.mechanism_id
        };
        if (this.channels == "合作商") {
          if (this.community_id == "") {
            this.$message("请选择社区机构");
            return;
          } else {
            data.community_id = this.community_id;
            data.cooperator_id = this.coop_id
          }
        }
        this.$axios
          .post("/user/commoditycoupon/insertCouponList", data)
          .then((res) => {
            this.GetList();
            this.toclose();
            this.$message({ message: res.data.message });
            this.grantNum = "";
            this.overdue = "";
            this.coupon_id = "";
            this.coupon = "";
            this.mechanism_id = "";
            this.coop_id = ''
            this.community_id = ''
          });
      }
    },
    // 批量领取
    drawCode() {
      this.dorw = true;
      //  console.log(this.sels)
      if (this.sels == "") {
        this.$message(
          {
            message: "请选中锦鲤码数据",
            type: "error",
          },
          2000
        );
      } else {
        this.show = true;
        this.id = [];
        let codes = [];
        this.sels.forEach((e) => {
          this.id.push(e.id);
          codes.push(e.coupon_code);
        });
        this.code = codes.join(",");
      }
    },
    handleClose(done) {
      this.addDialog = false;
      this.groupListShow = false;
      this.updateDialog = false;
      this.form = {};
      this.temp = {};
      done();
    },
    onSubmit() {
      this.currentPage = 1;
      this.GetList();
    },
  },
};
</script>
  
  <style >
</style>
  