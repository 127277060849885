<template>
    <div id="consumerCoupon">
      <el-row style="background-color: #ffffff">
        <content-title :nav="nav"></content-title>
      </el-row>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="生成列表" name="0">
             <coup-send></coup-send>    
        </el-tab-pane>
        <el-tab-pane label="兑换码列表" name="1">
             <coup-code-list></coup-code-list>
        </el-tab-pane>
        <el-tab-pane label="已领取列表" name="2">
             <coup-give-list></coup-give-list>
        </el-tab-pane>
      </el-tabs>
    </div>
  </template>
  
  <script>
  import coupSend from "./components/coupSend.vue";
  import coupCodeList from "./components/coupCodeList.vue";
  import coupGiveList from "./components/coupGiveList.vue";
  export default {
    components: {
    coupSend,
      coupCodeList,
      coupGiveList
    },
    data() {
      return {
        nav: { firstNav: "卡券管理中心", secondNav: "兑换码管理" },
        activeName: 0,
      }
    },
    methods: {
        handleClick() {},
    }
  };
  </script>
  
  <style lang="less">
  #consumerCoupon {
    background-color: #ffffff;
    padding: 0 20px;
    .an-box {
      display: flex;
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 30px;
      flex-wrap: wrap;
      .an-p {
        color: #ff7e00;
        border-radius: 5px;
        border: #ff7e00 1px solid;
        padding: 4px 8px;
        margin-right: 20px;
        height: 24px;
        line-height: 24px;
        @media screen and(max-width:768px) {
          margin-bottom: 20px;
        }
      }
    }
  }
  .el-tabs__item {
    font-size: 16px !important;
  }
  .cell {
    text-align: center;
  }
  </style>
  